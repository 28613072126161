<template>
    <ckeditor
        ref="editor"
        :value="value"
        :editor="editor"
        :config="editorConfig"
        @input="changeHandler"
        @ready="onReady" />
</template>

<script>
import mixins from './mixins/mixins.js'
import ClassicEditor from './utils/editor.js'
import { colors } from './utils/index.js'
export default {
    name: "CKEditor",
    mixins: [mixins],
    data() {
        return {
            editor: ClassicEditor,
            editorRef: null,
            editorConfig: {
                language: 'ru',
                mediaEmbed: {
                    previewsInData: true
                },
                fontBackgroundColor: {
                    colors
                },
                fontColor: {
                    colorPicker: {
                        format: 'hex'
                    },
                    colors
                },
                link: {
                    addTargetToExternalLinks: true
                },
                simpleUpload: {
                    uploadUrl: this.uploadUrl,
                    withCredentials: true,
                    headers: {
                        'X-CSRFToken': this.$cookies.get('csrftoken')
                    }
                }
            }
        }
    },
    created() {
        if(this.commentEditor) {
            this.editorConfig = {
                ...this.editorConfig,
                toolbar: {
                    viewportTopOffset: 0, 
                    items: [
                        'bold',
                        'italic',
                        'strikethrough',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                        'fontColor',
                        'fontBackgroundColor',
                        'imageInsert',
                        'mediaEmbed',
                        'codeBlock'
                    ]
                }
            }
        } else {
            this.editorConfig = {
                ...this.editorConfig,
                toolbar: {
                    viewportTopOffset: 0, 
                    items: [
                        'heading',
                        '|',
                        'alignment',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                        'fontColor',
                        'fontBackgroundColor',
                        'indent',
                        'outdent',
                        'pageBreak',
                        '|',
                        'imageInsert',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo',
                        'codeBlock'
                    ]
                },
                image: {
                    toolbar: [
                        'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        '|',
                        'imageTextAlternative',
                        '|',
                        'linkImage'
                    ]
                },
                table: {
                    contentToolbar: [
                        'toggleTableCaption',
                        '|',
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        '|',
                        'tableProperties',
                        'tableCellProperties'
                    ],
                    tableProperties: {
                        borderColors: colors,
                        backgroundColors: colors
                    },
                    tableCellProperties: {
                        borderColors: colors,
                        backgroundColors: colors
                    }
                },
                mention: {
                    feeds: [
                        {                    
                            marker: '#',
                            feed: this.getFeedItems,
                            itemRenderer: this.customItemRenderer
                        }
                    ]
                }
            }
        }
    },
    methods: {
        onReady(editor) {
            if(this.initFocus) {
                this.editorRef = editor
                this.editorFocus()
                if(this.commentEditor) {
                    this.EnterShiftHandler()
                }
            }
        },
        EnterShiftHandler() {
            this.editorRef.editing.view.document.on( 'enter', ( evt, data ) => {
                if (data.domEvent.keyCode === 13 && data.domEvent.shiftKey) {
                    data.preventDefault()
                    evt.stop()
                    this.enterShifthHand()
                }
            }, { priority: 'high' } )
        },
        editorFocus() {
            this.editorRef.focus()
            this.editorRef.model.change((writer) => {
                writer.setSelection(writer.createPositionAt(this.editorRef.model.document.getRoot(), "end"))
            })
        },
        insertText(text) {
            this.editorRef.focus()
            this.editorRef.model.change(writer => {
                const insertPosition = this.editorRef.model.document.selection.getFirstPosition()
                writer.insertText(text, insertPosition)
            })
        }
    }
}
</script>

<style lang="scss">
@import './scss/variables.scss';
.ck-content{
    @include ckeditorStyle;
}
.ck-toolbar__items{
    .ck-dropdown{
        .ck-icon{
            z-index: 100;
        }
    }
}
</style>