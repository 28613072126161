export const colors = [
    {
        color: '#000000',
        label: 'Черный'
    },
    {
        color: '#505050',
        label: 'Темно серый'
    },
    {
        color: '#ffffff',
        label: 'Белый'
    },
    {
        color: '#72849a',
        label: 'Серый'
    },
    {
        color: '#039be5',
        label: 'Голубой'
    },
    {
        color: '#f43c36',
        label: 'Глубокий карминно-розовый'
    },
    {
        color: '#ff5721',
        label: 'Красно-оранжевый'
    },
    {
        color: '#ff791b',
        label: 'Оранжевый'
    },
    {
        color: '#ffb400',
        label: 'Желтый'
    },
    {
        color: '#7cb342',
        label: 'Темно зеленый'
    },
    {
        color: '#209653',
        label: 'Зеленый'
    },
    {
        color: '#04ada0',
        label: 'Зеленый'
    },
    {
        color: '#3761e9',
        label: 'Синий'
    },
    {
        color: '#794cd8',
        label: 'Темно фиолетовый'
    },
    {
        color: '#a447bf',
        label: 'Фиолетовый'
    },
    {
        color: '#96a3b0',
        label: 'Серый'
    },
    {
        color: '#13b1e7',
        label: 'Голубой'
    },
    {
        color: '#ec407a',
        label: 'Розовый'
    },
    {
        color: '#ef304c',
        label: 'Красно розовый'
    },
    {
        color: '#8cadd0',
        label: 'Серый'
    }
]